import React, { Component } from "react";
import Layout from "../../components/layout";
import Breadcrumbs from "../../components/breadcrumbs";
import CustomHelmet from "../../components/customHelmet";
import BrokerLogin from "./login";
import Profile from "./profile";
import cx from "classnames";
import Styles from "./assets/brokerStyles.module.css";

class BrokerDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        const isLoggedIn = localStorage.getItem("isAuthenticated") === "true";
        this.setState({ isAuthenticated: isLoggedIn });
    }

    handleLoginSuccess = () => {
        localStorage.setItem("isAuthenticated", "true");
        this.setState({ isAuthenticated: true });
    };

    handleLogout = () => {
        localStorage.removeItem("isAuthenticated");
        this.setState({ isAuthenticated: false });
    };

    render() {
        let breadcrumbs = [{ name: "Broker Profile", link: "" }];

        return (

            <Layout >
                <CustomHelmet>
                    <title>HFG Marketplace - Broker Profile</title>
                    <meta name="description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable & fixed home loan rates from your local mortgage brokers." />
                    <meta name="keywords" content="home loan, mortgage, comparison rates" />
                    <meta property="og:description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable & fixed home loan rates from your local mortgage brokers." />
                    <meta property="og:title" content="HFG Marketplace - Home Loan Comparison | Compare Mortgage Rates" />
                    <meta property="og:url" content="https://marketplace.hashfinancialgroup.com/broker" />
                    <meta name="twitter:description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable & fixed home loan rates from your local mortgage brokers." />
                    <meta name="twitter:title" content="HFG Marketplace - Home Loan Comparison | Compare Mortgage Rates" />
                    <link rel="canonical" href="https://marketplace.hashfinancialgroup.com/broker" />
                </CustomHelmet>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className={Styles.brokerProfile}> 
                    {this.state.isAuthenticated ? (
                        <Profile onLogout={this.handleLogout} />
                    ) : (
                        <BrokerLogin onLoginSuccess={this.handleLoginSuccess} />
                    )}
                </div>
            </Layout>
        );
    }

};

export default BrokerDashboard;